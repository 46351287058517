import React from "react"
import styled from "@xstyled/styled-components"

import Layout from "../components/layout"
import SEO from "../components/atoms/Seo"

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Title = styled.h2`
  font-size: 40px;
  text-align: center;
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container>
      <Title>Page not found...</Title>
    </Container>
  </Layout>
)

export default NotFoundPage
